<template>
  <div class="children">
    <img src="@/assets/children/zh/Slice1.png" alt="">
    <img src="@/assets/children/zh/Slice2.png" alt="">
    <img src="@/assets/children/zh/Slice3.png" alt="">
    <img src="@/assets/children/zh/Slice4.png" alt="">
    <img src="@/assets/children/zh/Slice5.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
 .children {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    img {
        display: block;
        width: 100%;
    }
 }
</style>